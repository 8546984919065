import React from "react"
import { graphql } from "gatsby"
import Seo from "gatsby-plugin-wpgraphql-seo"

import Layout from "../../assets/styles/layout"

import { FooterContainer } from "../../components/global/common/containers"

import CallToAction from "../../components/global/CallToAction"
import Footer from "../../components/global/Footer"
import Technologies from "../../components/global/Technologies"

import OfferHeader from "../../components/global/header/OfferHeader"
import Target from "../../components/offer/Target"
import WhyUs from "../../components/offer/WhyUsVideo"
import WhatWeCanDo from "../../components/offer/WhatWeCanDo"
import Services from "../../components/offer/Services"
import HowMuch from "../../components/offer/HowMuch"
import HourWorkDivider from "../../components/offer/HourWorkDivider"
import Delivery from "../../components/offer/Delivery"
import WhyTechnology from "../../components/offer/WhyTechnology"
import CaseStudies from "../../components/global/OfferCaseStudies"
import Faq from "../../components/global/Faq"

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      acfCallToAction {
        ctaPersonPosition
        ctaPersonName
        ctaForm
        ctaImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        ctaContactOptions {
          eMail
          phone
        }
        ctaButtons {
          ctaButton1 {
            url
            title
            target
          }
          ctaButton2 {
            target
            url
            title
          }
        }
        ctaIntro
        ctaTitle
      }
      acfOffer {
        dividerSectionExists
        dividerText
        deliveryTitle
        howMuchSectionExists
        howMuchTitle
        offerCaseStudiesExists
        intro
        offerCaseStudiesTitle
        offerDeliveryExists
        faqExists
        faqTitle
        offerServicesSectionExists
        offerServicesTitle
        offerTargetSectionExists
        offerTargetTitle
        offerWhatWeCanDoSectionExists
        offerWhatWeCanDoTextBelowTitle
        offerWhatWeCanDoTitle
        offerWhyTechnologyExists
        offerWhyUsSectionExists
        offerWhyUsMovie
        offerTechnologiesExists
        offerTechnologiesDescription
        offerTechnologiesGallery {
          id
          altText
          localFile {
            publicURL
          }
        }
        testimonialAuthorName
        testimonialAuthorPosition
        title
        whyTechnologyBigDescription
        whyTechnologyLastDescription
        whyTechnologySmallDescription
        whyTechnologyTitle
        whyUsTextBelowTitle
        whyUsTitle
        whyUsTextBelowSubtitle
        whyUsSubtitle
        whyTechnologyList {
          icon
          description
        }
        testimonialAuthorLogo {
          altText
          localFile {
            publicURL
          }
        }
        offerWhatCanWeDoList {
          csImportantPointsSubTitle
          csImportantPointsDescription
        }
        offerServicesTechnologies {
          header
          logos {
            id
            altText
            localFile {
              publicURL
            }
          }
        }
        offerServicesFiles {
          header
          logos {
            id
            altText
            localFile {
              publicURL
            }
          }
        }
        offerServicesFieldList {
          name
          icon
        }
        faqList {
          header
          description
        }
        offerCaseStudiesList {
          ... on WpCaseStudy {
            id
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                  }
                }
              }
            }
            acfCaseStudy {
              intro
              whiteLogo {
                altText
                localFile {
                  publicURL
                }
              }
            }
            title
            uri
          }
        }
        targetGroups {
          description
          title
        }
        image {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        dividerButton {
          url
          title
          target
        }
        deliveryList {
          offerDeliveryDescription
          offerDeliveryNumber
        }
        costs {
          title
          textUnderTitle
          price
          howMuchSectionCostList {
            title
            content
          }
          button {
            url
            title
            target
          }
          caseStudy {
            ... on WpCaseStudy {
              id
              featuredImage {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                    }
                  }
                }
              }
              acfCaseStudy {
                whiteLogo {
                  altText
                  localFile {
                    publicURL
                  }
                }
              }
              uri
            }
          }
        }
      }
    }
  }
`

const Offer = ({ pageContext, data: { wpPage } }) => {
  const { seo } = wpPage
  const offerData = wpPage.acfOffer

  const is = {
    target: offerData.offerTargetSectionExists,
    whyUs: offerData.offerWhyUsSectionExists,
    whatWeCanDo: offerData.offerWhatWeCanDoSectionExists,
    technologies: offerData.offerTechnologiesExists,
    services: offerData.offerServicesSectionExists,
    howMuch: offerData.howMuchSectionExists,
    hourWorkDivider: offerData.dividerSectionExists,
    delivery: offerData.offerDeliveryExists,
    whyTechnology: offerData.offerWhyTechnologyExists,
    caseStudies: offerData.offerCaseStudiesExists,
    faq: offerData.faqExists,
  }

  return (
    <Layout>
      {seo && process.env.GATSBY_SITE_ENV === "production" && (
        <Seo post={wpPage} />
      )}
      <OfferHeader data={offerData} pageContext={pageContext} />
      {is.target && <Target data={offerData} />}
      {is.whyUs && <WhyUs data={offerData} />}
      {is.whatWeCanDo && <WhatWeCanDo data={offerData} />}
      {is.technologies && (
        <Technologies
          description={offerData.offerTechnologiesDescription}
          technologies={offerData.offerTechnologiesGallery}
          containerStyle={{ paddingTop: "0", marginTop: "-1rem" }}
        />
      )}
      {is.services && <Services data={offerData} />}
      {is.howMuch && <HowMuch data={offerData} />}
      {is.hourWorkDivider && <HourWorkDivider data={offerData} />}
      {is.delivery && <Delivery data={offerData} />}
      {is.whyTechnology && <WhyTechnology data={offerData} />}
      {is.caseStudies && <CaseStudies data={offerData} />}
      {is.faq && <Faq data={offerData} />}
      <FooterContainer>
        <CallToAction data={wpPage.acfCallToAction} />
        <Footer />
      </FooterContainer>
    </Layout>
  )
}

export default Offer
