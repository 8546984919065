import React from "react"
import styled from "styled-components"

import { minD, maxD } from "../../assets/styles/helpers/devices"

import { LineWrapperWithSpaces } from "../global/common/containers"
import { FadedGreenBigBox, greenBox } from "../global/common/ornaments"
import { MainTitle, BiggerDescription } from "../global/common/text"

import Points from "../global/Points"

const SkillsWrapper = styled(LineWrapperWithSpaces)`
  @media ${maxD.tabletL} {
    flex-wrap: wrap;
  }
`

const SkillsIntro = styled(FadedGreenBigBox)`
  width: 100%;

  flex-shrink: 0;
  z-index: -1;

  @media ${minD.tabletL} {
    padding-right: 50px;
    width: 350px;

    ${greenBox}
  }

  @media ${minD.laptop} {
    width: 600px;
    padding-right: 120px;
  }

  @media ${maxD.tabletL} {
    margin-bottom: 3rem;
  }

  &::before {
    top: calc(var(--section-spacing) * -1);
    left: calc(var(--section-spacing) * -1);

    --box-width: 900px;
    --box-height: calc(100% - var(--section-spacing));

    @media ${maxD.tabletL} {
      --box-width: 95%;
      --box-height: 500px;
    }
  }

  &::after {
    top: calc(100% - (var(--section-spacing) * 2) - 20px);
    left: 20px;
  }
`

const SkillsPoints = styled.div``

const WhatWeCanDo = ({ data }) => {
  const {
    offerWhatWeCanDoTitle: title,
    offerWhatWeCanDoTextBelowTitle: description,
    offerWhatCanWeDoList: list,
  } = data

  return (
    <SkillsWrapper flex>
      <SkillsIntro>
        <MainTitle
          dangerouslySetInnerHTML={{ __html: title }}
          style={{
            "--padding-bottom": "2rem",
          }}
        />
        <BiggerDescription dangerouslySetInnerHTML={{ __html: description }} />
      </SkillsIntro>
      <SkillsPoints>
        <Points
          points={list}
          style={{
            "--point-background-color": "#fff",
            "--point-border-color": "var(--faded-green)",
          }}
        />
      </SkillsPoints>
    </SkillsWrapper>
  )
}

export default WhatWeCanDo
