import React from "react"
import styled from "styled-components"

import { minD, maxD } from "../../assets/styles/helpers/devices"

import {
  GrayContainer,
  LineWrapperWithBottomSpace,
} from "../global/common/containers"
import {
  MainTitle,
  MiddleSpacedBigDescription,
  CenterText,
} from "../global/common/text"
import { EqualBox, UnstyledBoxes, BoxIcon } from "../case-study/common/boxes"

const WhyTechnologyContent = styled.article`
  @media ${minD.tablet} {
    padding: 60px 60px 0;
  }

  @media ${minD.laptop} {
    padding: 60px 120px 0;
  }
`

const WhyTechnologyBoxes = styled(UnstyledBoxes)`
  padding: 3rem 0;

  @media ${maxD.mobileM} {
    padding: 2.4rem 0;
  }
`

const WhyTechnologyBox = styled(EqualBox)`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 25%;
  padding: 2rem;

  @media ${maxD.laptop} {
    width: 50%;
  }

  @media ${maxD.tablet} {
    width: 100%;
  }
`

const WhyTechnologyPointText = styled(CenterText)`
  padding-top: 1.6rem;

  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.3;
`

const WhyTechnology = ({ data }) => {
  const {
    whyTechnologyTitle: title,
    whyTechnologyBigDescription: bigDescription,
    whyTechnologySmallDescription: smallDescription,
    whyTechnologyList: list,
    whyTechnologyLastDescription: lastDescription,
  } = data

  return (
    <GrayContainer>
      <LineWrapperWithBottomSpace>
        <WhyTechnologyContent>
          <MainTitle
            dangerouslySetInnerHTML={{ __html: title }}
            style={{
              "--padding-bottom": "1rem",
            }}
          />
          <MiddleSpacedBigDescription
            dangerouslySetInnerHTML={{ __html: bigDescription }}
          />
          <p dangerouslySetInnerHTML={{ __html: smallDescription }} />
        </WhyTechnologyContent>
        <WhyTechnologyBoxes>
          {list &&
            list.map(({ icon, description }, i) => (
              <WhyTechnologyBox key={`technology-${i}`}>
                <BoxIcon dangerouslySetInnerHTML={{ __html: icon }}></BoxIcon>
                <WhyTechnologyPointText
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </WhyTechnologyBox>
            ))}
        </WhyTechnologyBoxes>
        <WhyTechnologyContent>
          <p dangerouslySetInnerHTML={{ __html: lastDescription }} />
        </WhyTechnologyContent>
      </LineWrapperWithBottomSpace>
    </GrayContainer>
  )
}

export default WhyTechnology
